import anime from "animejs";

export function toggleMenu() {
  const hamburger = document.querySelector('#hamburger');
  const mainMenu = document.querySelector('#main-menu');
  const tl = anime.timeline({
    targets: mainMenu,
    autoplay: false,
    easing: 'easeInOutSine'
  }).add({
    height: '100vh',
    duration: 1,
  }).add({
    opacity: 1,
    duration: 300,
  });
  hamburger.addEventListener('click', () => {
    document.body.classList.toggle('open');
    hamburger.classList.toggle('open');
    if (!mainMenu.classList.contains('active')) {
      mainMenu.classList.add('active');
      if (tl.reversed) {
        tl.reverse();
      }
      tl.play();
    } else {
      mainMenu.classList.remove('active');
      tl.reverse();
      tl.play();
    }
  });
}
